<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Advertising from  "@/services/Advertising";
import Swal from "sweetalert2";
import modalAddSponsorWall from "@/components/modals/advertising/modalAddSponsorWall";

export default {
  components: { Layout, PageHeader, modalAddSponsorWall },
  page: {
    title: "Sponsor Wall",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
        title: "Sponsor Wall",
        items: [
            {
                text: "Advertising",
                href: "/",
            },
            {
                text: "Sponsor Wall",
                active: true,
            },
        ],
        tableData: [],
        error: null,

        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "round",
        sortDesc: false,
        noResultsText: 'No Data Found',
        fields: [
            {
              key: "id",
              label: "Wall ID",
              sortable: true,
            },
            {
              key: "media_id",
              label: "Media Id",
              sortable: true,
            },
            {
              key: "media_source_url",
              label: "Media Image",
              sortable: true,
            },
            {
              key: "media_width",
              label: "Media Width",
              sortable: true,
            },
            {
              key: "media_height",
              label: "Media Height",
              sortable: true,
            },
            {
              key: "link_url",
              label: "Link URL",
              sortable: true,
            },
            {
              key: "sponsor_name",
              label: "Sponsor Name",
              sortable: true,
            },
            {
              key: "timestamp",
              label: "Timestamp",
              sortable: true,
            },

            "action",
        ],
    };
  },
  middleware: "authentication",
  computed: {
      /**
       * Total no. of records
       */
      rows() {
        return this.tableData.length;
      },
  },
  mounted() {
    // Set the initial number of items
    //this.totalRows = this.items.length;
  },
  created() {
      this.getSponsorWall()
  },
  methods: {
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        getSponsorWall(){
            Advertising.getSponsorWall()
            .then(response => {
                //console.log(response)
                this.tableData = response.data.data;
            })
            .catch(error => {
                this.tableData = [];
                this.error = error.response.data.error ? error.response.data.error : "";
            })
        },

        removeFromWall(wall_id) {
            Swal.fire({
                title: "Are you sure?",
                text: "This Sponsor will no longer be listed on Sponsor Wall !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, remove it!",
            }).then((result) => {
                if (result.value) {
                        Advertising.detachSponsorWall({
                            id: wall_id
                        })
                        .then((response) => {
                            const res = response.data.data ? response.data.data : false;
                            const error = response.data.error ? response.data.error : 'Failed';
                            if(res){
                                this.getSponsorWall();
                                Swal.fire("Removed!", "Sponsor Removed from Sponsor Wall !", "success");
                            }else{
                                Swal.fire("Fail!", error, "warning");
                            }
                        })
                        .catch(error => {
                            this.getSponsorWall();
                            Swal.fire("Fail!", error, "warning");
                        });
                }
            });
        },

        modalAddSponsorWall() {
            this.$bvModal.show("add_sponsor_wall");
        },

        

  },
};

</script>



<template>
    <Layout>
      <PageHeader :title="title" :items="items" />
  
      <div class="row">
        <div class="col-12">
          <div>
              <button type="button" class="btn btn-success mb-3" @click="modalAddSponsorWall()">
                  <i class="mdi mdi-plus me-1"></i> Add New Wall
              </button>
          </div>
        </div>
      </div>
  
  
      <div class="row">
        <div class="col-12">
          
          <div class="table table-centered datatable dt-responsive nowrap table-card-list dataTable no-footer dtr-inline">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center fw-normal">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center fw-normal">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            
            </div>
            <!-- Table -->
  
            <b-table
              table-class="table table-centered datatable table-card-list"
              thead-tr-class="bg-transparent"
              :items="tableData"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              show-empty
              empty-text="No Data Found"
              empty-filtered-text="No Data Found"
            >
                <template v-slot:cell(media_source_url)="data">
                    <img class="img-fluid" width="100" :src="data.item.media_source_url" >
                </template> 

                <template v-slot:cell(link_url)="data">
                    <a :href="data.item.link_url" target="_blank">{{data.item.link_url}}</a>
                </template>

                <template v-slot:cell(action)="data">
                    <ul class="list-inline mb-0">
                        <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Remove from Sponsored" @click="removeFromWall(data.item.id)">
                            <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                    </ul>
                </template>

            </b-table>

          </div>

          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  
  
    <!-- MODALS --> 
    <modalAddSponsorWall @refreshTable="getSponsorWall" />

    <!-- END  MODALS -->

  
  
    </Layout>
  </template>
  